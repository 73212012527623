import Vue from "vue";
import VueI18n from "vue-i18n";
import LocaleService from "@/services/LocaleService";
import { getConfig } from "./config";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const config = getConfig();

  const locales = {
    main: require.context("./locales/all", true, /[A-Za-z0-9-_,\s]+\.json$/i),
    // default: require.context("./locales/default", true, /[A-Za-z0-9-_,\s]+\.json$/i),
    // swa: require.context("./locales/swa", true, /[A-Za-z0-9-_,\s]+\.json$/i),
  };

  const messages = {};
  const babel = config?.locales || 'main'

  locales[babel].keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales[babel](key);
    }
  });

  return messages;
}

export default new VueI18n({
	fallbackLocale: "nb-NO",
	locale: LocaleService.getLocale(),
  messages: loadLocaleMessages(),
});
