<template>
  <div class="relative flex-1 p-4 overflow-y-auto bg-gray-100 shadow-inner">
    <PLoader v-if="loading" />
    <slot />
  </div>
</template>

<script>
export default {
  name: "p-dialog-content",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>