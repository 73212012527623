<template>
  <portal to="modals">
    <div class="fixed inset-0 z-50 flex items-center justify-center confirm">
      <div
        class="relative flex flex-col p-8 overflow-y-hidden bg-white shadow-xl w-96 rounded-md max-h-3/4"
      >
        <div class="text-xl font-semibold flex-0" v-html="title"></div>

        <div class="flex-1 mt-4 overflow-y-auto">
          <slot />
        </div>

        <slot name="buttons">
          <div class="flex justify-between mt-8">
            <PButton
              class="w-32"
              color="primary"
              @click="$emit('accept')"
              v-html="acceptButtonText"
            ></PButton>

            <PButton
              class="w-32"
              color="danger"
              @click="$emit('reject')"
              v-html="rejectButtonText"
            ></PButton>
          </div>
        </slot>
        <div
          v-if="loading"
          class="absolute top-0 bottom-0 left-0 right-0 flex justify-center p-8 bg-gray-400op"
        >
          <p-loader class="m-auto" />
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  name: "p-confirm",

  props: {
    title: { type: String, default: "" },
    acceptButtonText: {
      type: String,
      default() {
        return this.$tk("Common.General.Yes");
      },
    },
    rejectButtonText: {
      type: String,
      default() {
        return this.$tk("Common.General.No");
      },
    },
    type: {
      type: String,
      default: "",
      validator: function (value) {
        return (
          ["", "warning", "info", "error"].indexOf(
            (value ?? "").toLowerCase()
          ) !== -1
        );
      },
    },
    loading: { type: Boolean, default: false },
  },
};
</script>

<style>
.confirm {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>