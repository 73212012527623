<template>
  <div
    class="fixed inset-0 z-50 flex items-start px-4 py-6 pointer-events-none sm:p-6"
  >
    <transition-group
      class="w-full max-w-sm"
      tag="ul"
      enter-active-class="transform ease-out duration-300 transition"
      enter-class="opacity-0 translate-x-4"
      enter-to-class="opacity-100 translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-class="opactity-100"
      leave-to-class="opacity-0"
      move-class="transition ease-in-out duration-500"
    >
      <PNotification @close="remove" v-for="notification in notifications" :notification="notification" :duration="duration" :key="notification.id" />
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import PNotification from "@/components/PNotification";

export default {
  name: "PNotifications",
  props: {
    duration: {
      type: Number,
      default: 10000
    },
  },
  computed: {
    ...mapGetters (
      ["notifications"]
    ),
    ...mapMutations (
      ["removeNotification"]
    )
  },
  components: {
    PNotification
  },
  methods: {
    remove (notification) {
      this.$store.commit("removeNotification", notification)
    }
  }
}
</script>
