<template>  
  <span class="inline-block px-4 py-1 font-medium rounded-full" :class="statusClass">
    {{ displayText }}     
  </span>
</template>

<script>

export default {

  props: ["item", "value"],

  computed: {

    status () {
      return this.item.status
    },
    
    statusClass () {
      return this.status === "OK"          ? "bg-green-100 text-green-700" :             
             this.status === "NeedsRepair" ? "bg-red-100 text-red-700" :
                                             "bg-gray-200 text-gray-500"
    },    

    displayText () {
      return this.status === "OK"          ? "OK" : 
             this.status === "NeedsRepair" ? "Må repareres" : this.status 
    }
  }  
}
</script>