<template>
  <button aria-label="Close panel" class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150" @click="$emit('close')">
    <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>
</template>

<script>
  export default {
    name: "PCloseButton"
  }
</script>