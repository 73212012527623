<template>
  <div class="relative flex-1 overflow-y-scroll" @scroll="onScroll" ref="scrollContainer">    
    <POrderGridTable
      :cols="cols"
      :orders="orders"
      :nested="nested"
      :expandAll="expandAll"
      :expandable="expandable"
      :totalCount="totalCount"
      :loading="ordersLoading"
      :orderByField="orderByField"
      :orderByDescending="orderByDescending"
      :showFooter="true"
      @sort="onSort"
      @click="onClick"
    >
    </POrderGridTable>
  </div>
</template>

<script>

import api from "@/api/orders"
import POrderGridTable from "./POrderGridTable"
import { mapGetters } from "vuex"
import { 
  get, 
  debounce 
} from "lodash"

const PAGE_SIZE = 50

export default {
  name: "POrderGrid",
  components: {
    POrderGridTable
  },
  props: {    
    transactionType: {
      type: String,
      default: ""
    },
    customerId: {
      type: String,
      default: ""
    },
    locationId: {
      type: String,
      default: ""
    },
    locationIdFrom: {
      type: String,
      default: ""
    },
    locationIdTo: {
      type: String,
      default: ""
    },
    nested: {
      type: String,
      default: "transactions"
    },
    cols: {
      type: Array,
      required: true
    },
    filters: {
      required: false
    },
    orderBy: {
      required: false
    },
    method: {
      type: String,
      default: "orders"
    },
    expandable: {
      type: Boolean,
      default: true
    },
    expandAll: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      allOrders: [],
      ordersLoading: false,
      pageIndex: 0,
      orderByField: "",
      orderByDescending: false
    }
  },

  computed: {

    ...mapGetters(["location"]),

    orders () {      
      const start = this.pageIndex * PAGE_SIZE
      const end = start + PAGE_SIZE
      return this.allOrders.slice(0, end)
    },
    
    totalCount () {
      return this.allOrders.length
    }

  },
  methods: {
    onClick (order, component) {
      this.$emit("click", { order, component })
    },
    onSort (col) {
      if (col.sortable) {  
        
        this.$refs.scrollContainer.scrollTo(0, 0)

        this.orderByDescending =
          col.value === this.orderByField
            ? this.orderByDescending
              ? false
              : true
            : true

        this.orderByField = col.value

        this.getOrders()
      }
    },    
    onScroll: debounce(function(event) {           
      const distanceFromBottom = event.target.scrollHeight - 
        (event.target.offsetHeight + event.target.scrollTop)        
        
        if (distanceFromBottom < 1000 && !this.ordersLoading)
        
        this.getNextOrders()

    }, 100),

    async getOrders () {
      
      this.ordersLoading = true

      let params = {}

      if (this.customerId) params.customerId = this.customerId
      if (this.transactionType) params.transactionType = this.transactionType
      if (this.locationIdFrom) params.locationIdFrom = this.locationIdFrom
      if (this.locationIdTo) params.locationIdTo = this.locationIdTo

      if (this.method === "ExternalOrders") {

        this.allOrders = await api.getExternalOrders(
          params,
          this.orderByField, 
          this.orderByDescending, 
          this.filters
        )

      } else {

        if (this.locationId) params.locationId = this.locationId

        this.pageIndex = 0

        try {
          
          const orders = await api.getOrders(
            this.method,
            params,          
            this.orderByField, 
            this.orderByDescending, 
            this.filters,
            PAGE_SIZE
          )
          
          if (orders) {
            this.allOrders = orders
          }

        } catch (error) {

          this.$store.dispatch("notify", { 
            type: "negative", 
            text: error.reason 
          })

        }

      }

      this.ordersLoading = false
    },

    async getNextOrders () {

      const start = (this.pageIndex + 1) * PAGE_SIZE
      const end = start + PAGE_SIZE
      
      if (start < this.allOrders.length) {
        
        this.ordersLoading = true

        const ids = this.allOrders.slice(start, end).map(order => order.id)
        const orders = await api.getOrdersById(this.location.id, ids)
      
        this.allOrders.splice(start, PAGE_SIZE, ...orders)

        this.pageIndex = this.pageIndex + 1
        this.ordersLoading = false
      }
    }
  },

  created () {

    this.orderByField = get(this.orderBy, "field", "")
    this.orderByDescending = get(this.orderBy, "descending", false)
    
    this.getOrders() 
  },

  watch: {
    filters: {
      handler: function () {              
        this.getOrders()
      }, deep: true
    },
    // location: {
    //   handler: function(location) {
    //     if (location) {
    //       this.getOrders()
    //     }
    //   }
    // }
  }
}

</script>