<template>
  <div class="flex flex-col items-center md:flex-row space-y-5 md:space-y-0" v-if="value">
    
    <div class="flex flex-row flex-wrap ml-4 md:flex-no-wrap"> 
      <PToggle 
        class="w-20 my-2 md:my-0" 
        v-show="status.visible !== false"
        v-for="(status, index) in value.statuses" 
        :key="index"
        :value="status.value" 
        :stacked="true"
        :label="getLabel(status)"
        @input="setStatus(index, $event)" 
      />
    </div>

    <div class="flex space-x-4">

      <PDatePicker 
        class="ml-4 w-36" 
        v-if="value.sendLower && value.sendLower.visible !== false" 
        @input="set('sendLower', $event)" 
        :placeholder="$tk('Common.General.FromDate')" 
        :value="sendLower" 
        :clearable="true" 
        :max="sendLowerMax" 
      />

      <PDatePicker 
        class="ml-2 w-36" 
        v-if="value.sendUpper && value.sendUpper.visible !== false" 
        :placeholder="$tk('Common.General.ToDate')" 
        @input="set('sendUpper', $event)" 
        :value="sendUpper" 
        :clearable="true" 
        :min="sendUpperMin" 
        :rightAlign="true"
      />
    </div>

    <PInput 
      class="ml-2 md:w-36" 
      style="min-width:8rem;"
      icon-inside-left="search" 
      v-if="value.term && value.term.visible !== false" 
      :placeholder="$tk('Common.General.SearchText', true)" 
      @input="set('term', $event)" 
      :value="value.term.value" 
      :wait="250" 
      :clearable="true" 
    />
    
  </div>
</template>

<script>

import { get } from "lodash"

export default {
  
  props: {
    value: {
      type: Object,
      required: true
    }
  },

  computed: {
    sendLower () {
      return get(this.value, "sendLower.value", "")
    },
    sendUpper () {
      return get(this.value, "sendUpper.value", "")
    },
    sendLowerMax () {
      return this.sendUpper
    },
    sendUpperMin () {
      return this.sendLower
    },
  },

  methods: {
    
//  0 web                 Kladd
//  1 ordered             Bestilt
//  2 confirmedWaba       Bekreftet
//  3 picked              Plukket
//  4 sent                Sendt
//  5 confirmed           Mottatt
//  6 notAgreed           Uenighet
//  7 cancelledWaba       Kansellert
//  9 waitForSort         Sorteres
// 10 treatment           Under beh.
// 11 waitForConfirmation Bekr. kunde
// 12 receivedSort        Ankommet
// 13 waitForAccept       Sortert
// 14 waitForAgreement    Sortert m/m

    getLabel (status) {
      if (status.label) {
        return status.label 
      } else {
        return status.id ===  0 ? this.$tk("Common.OrderStatus.Draft") :
               status.id ===  1 ? this.$tk("Common.OrderStatus.Ordered")     :
               status.id ===  2 ? this.$tk("Common.OrderStatus.ConfirmedWabaShort") :
               status.id ===  3 ? this.$tk("Common.OrderStatus.PickedShort") :
               status.id ===  4 ? this.$tk("Common.OrderStatus.Sent") :
               status.id ===  5 ? this.$tk("Common.OrderStatus.Received") :
               status.id ===  6 ? this.$tk("Common.OrderStatus.NotAgreed") :
               status.id ===  7 ? this.$tk("Common.OrderStatus.CancelledWabaShort") :
               status.id ===  9 ? this.$tk("Common.OrderStatus.WaitForSort") :
               status.id === 10 ? this.$tk("Common.OrderStatus.TreatmentShort") :
               status.id === 11 ? this.$tk("Common.OrderStatus.WaitForConfirmationShort") :
               status.id === 12 ? this.$tk("Common.OrderStatus.ReceivedSortShort") :
               status.id === 13 ? this.$tk("Common.OrderStatus.WaitForAccept") :
               status.id === 14 ? this.$tk("Common.OrderStatus.WaitForAgreementShort") : ""
      }
    },

    set (property, value) {
      let filters = { ...this.value }
      filters[property].value = value
      this.$emit("input", filters)
    },

    setStatus (index, $event) {
      let filters = { ...this.value }
      filters.statuses[index].value = $event
      this.$emit("input", filters)      
    }

  }

}
</script>