const LOCALE_SE = "sv-SE";
const LOCALE_SE_SW = "sv-SE-sw";
const LOCALE_DK = "da-DK";
const LOCALE_DK_SW = "da-DK-sw";
const LOCALE_FI = "fi-FI";
const LOCALE_FI_SW = "fi-FI-sw";
const LOCALE_NO = "nb-NO";
const LOCALE_NO_SW = "nb-NO-sw";

const LocaleService = {
	getLocale() {
		// *** For pages when not logged in
		let locale = localStorage.getItem("locale");
		if (locale) return locale;

		if (location.hostname === 'localhost')
			return locale || LOCALE_DK_SW

		const isSmartWash =
			location.hostname.toLowerCase().indexOf("smartwash") >= 0;
		let suffix = "";
		const index = location.hostname.lastIndexOf(".");
		if (index > 0)
			suffix = location.hostname.substr(index).toLocaleLowerCase();

		switch (suffix) {
			case ".se":
				return isSmartWash ? LOCALE_SE_SW : LOCALE_SE;
			case ".dk":
				return isSmartWash ? LOCALE_DK_SW : LOCALE_DK;
			case ".fi":
				return isSmartWash ? LOCALE_FI_SW : LOCALE_FI;
			default:
				return isSmartWash ? LOCALE_NO_SW : LOCALE_NO;
		}
	},

	setLocale(locale) {
		localStorage.setItem("locale", locale);
	},
};

export default LocaleService;
