import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import I18nTranslateKey from "@/plugins/I18nTranslateKey";
Vue.use(I18nTranslateKey);

import PortalVue from "portal-vue";
Vue.use(PortalVue);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import VueAppInsights from "@/plugins/VueApplicationInsights";

Vue.use(VueAppInsights, {
	id: "83a99846-23f0-449b-a83d-b7a12bf1211b",
	appInsights: window.appInsights,
	router,
	appInsightsConfig: {
		enableAutoRouteTracking: true,
		enableAjaxErrorStatusText: true,
	},
});

import NotificationPlugin from "@/plugins/Notifications";
Vue.use(NotificationPlugin);

import ApiPlugin from "@/plugins/ApiUtilities";
Vue.use(ApiPlugin);

import AppConfig from "@/plugins/AppConfig";
Vue.use(AppConfig);

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

import HighchartsVue from "highcharts-vue";
Vue.use(HighchartsVue);

import ShortKey from "vue-shortkey";
Vue.use(ShortKey);

import TextareaAutosize from "vue-textarea-autosize";
Vue.use(TextareaAutosize);

import VueSignaturePad from "vue-signature-pad";
Vue.use(VueSignaturePad);

Vue.config.productionTip = false;

import AreaContainer from "@/components/AreaContainer.vue";
import AreaContent from "@/components/AreaContent.vue";
import AreaNav from "@/components/AreaNav.vue";

import POrderGrid from "@/components/ordergrid/POrderGrid.vue";
import PConfirm from "@/components/PConfirm.vue";
import PCard from "@/components/PCard.vue";
import PCardTitle from "@/components/PCardTitle.vue";
import PCardContent from "@/components/PCardContent.vue";
import PCardActions from "@/components/PCardActions.vue";
import PClickOutside from "@/components/PClickOutside.vue";
import PLoader from "@/components/PLoader.vue";
import PProductsPicker from "@/components/PProductsPicker.vue";
import PDialog from "@/components/dialog/PDialog.vue";
import PDialogBody from "@/components/dialog/PDialogBody.vue";
import PDialogContent from "@/components/dialog/PDialogContent.vue";
import PDialogActions from "@/components/dialog/PDialogActions.vue";
import PTabs from "@/components/tabs/PTabs.vue";
import PTab from "@/components/tabs/PTab.vue";
import PCloseButton from "@/components/PCloseButton.vue";
import PAlert from "@/components/PAlert.vue";
import PTruckFill from "@/components/PTruckFill.vue";
import PFlag from "@/components/PFlag.vue";
import PThumbnailImage from "@/components/PThumbnailImage.vue";
import PButtonGroup from "@/components/PButtonGroup.vue";
import PLinkButton from "@/components/PLinkButton.vue";

import PTable from "@/components/PTable.vue";
import PText from "@/components/forms/PText.vue";
import PButton from "@/components/forms/PButton.vue";
import PInput from "@/components/forms/PInput.vue";
import PInputGLN from "@/components/forms/PInputGLN.vue";
import PSelect from "@/components/forms/PSelect.vue";
import PTextarea from "@/components/forms/PTextarea.vue";
import POptionGroup from "@/components/forms/POptionGroup.vue";
import PCheckbox from "@/components/forms/PCheckbox.vue";
import PRadio from "@/components/forms/PRadio.vue";
import PToggle from "@/components/forms/PToggle.vue";
import PNumber from "@/components/forms/PNumber.vue";
import PFile from "@/components/forms/PFile.vue";
import PDatePicker from "@/components/forms/PDatePicker.vue";
import PProductPicker from "@/components/forms/PProductPicker.vue";
import PLocationPicker from "@/components/forms/PLocationPicker.vue";
import PStatusPicker from "@/components/forms/PStatusPicker.vue";
import PWeekdayPicker from "@/components/forms/PWeekdayPicker.vue";
import PPostCodePicker from "@/components/forms/PPostCodePicker.vue";

import POrderFilters from "@/components/ordergrid/POrderFilters.vue";
import PDisplayComments from "@/components/ordergrid/PDisplayComments.vue";
import PDisplayDate from "@/components/ordergrid/PDisplayDate.vue";
import PDisplayNumber from "@/components/ordergrid/PDisplayNumber.vue";
import PDisplayStatus from "@/components/ordergrid/PDisplayStatus.vue";
import PDisplayText from "@/components/ordergrid/PDisplayText.vue";
import PDisplayProduct from "@/components/ordergrid/PDisplayProduct.vue";
import PDisplayProductImages from "@/components/ordergrid/PDisplayProductImages.vue";
import PDisplaySum from "@/components/ordergrid/PDisplaySum.vue";
import PDisplayUser from "@/components/ordergrid/PDisplayUser.vue";
import PDisplayBoolean from "@/components/ordergrid/PDisplayBoolean.vue";
import PDisplayAutoCommit from "@/components/ordergrid/PDisplayAutoCommit.vue";
import PDisplayAddress from "@/components/ordergrid/PDisplayAddress.vue";
import PDisplayPrice from "@/components/ordergrid/PDisplayPrice.vue";
import PDisplayLocation from "@/components/ordergrid/PDisplayLocation.vue";
import PDisplayInfo from "@/components/ordergrid/PDisplayInfo.vue";
import PDisplayContainerStatus from "@/components/ordergrid/PDisplayContainerStatus.vue";
import PDisplayContainerOrderStatus from "@/components/ordergrid/PDisplayContainerOrderStatus.vue";
import PDisplayContainerRentalStatus from "@/components/ordergrid/PDisplayContainerRentalStatus.vue";
import PDisplayContainerOrderCurrent from "@/components/ordergrid/PDisplayContainerOrderCurrent.vue";
import PDisplayCustomsClearance from "@/components/ordergrid/PDisplayCustomsClearance.vue";
import PDisplaySent from "@/components/ordergrid/PDisplaySent";
import PDisplaySumSent from "@/components/ordergrid/PDisplaySumSent";
import PDisplayScheduleFill from "@/components/ordergrid/PDisplayScheduleFill";
import PDisplayCounterpart from "@/components/ordergrid/PDisplayCounterpart";

import PRentalStatus from "@/components/PRentalStatus.vue";
import PImage from "@/components/PImage.vue";
import PTooltip from "@/components/PTooltip.vue";
import PTranslation from "@/components/PTranslation.vue";
import PAddressItem from "@/components/PAddressItem.vue";
import PVersionChecker from "@/components/PVersionChecker.vue";

Vue.component("AreaContainer", AreaContainer);
Vue.component("AreaContent", AreaContent);
Vue.component("AreaNav", AreaNav);

Vue.component("PCloseButton", PCloseButton);
Vue.component("POrderGrid", POrderGrid);
Vue.component("PConfirm", PConfirm);
Vue.component("PCard", PCard);
Vue.component("PCardTitle", PCardTitle);
Vue.component("PCardContent", PCardContent);
Vue.component("PCardActions", PCardActions);
Vue.component("PClickOutside", PClickOutside);
Vue.component("PLoader", PLoader);
Vue.component("PDialog", PDialog);
Vue.component("PDialogBody", PDialogBody);
Vue.component("PDialogContent", PDialogContent);
Vue.component("PDialogActions", PDialogActions);
Vue.component("PTabs", PTabs);
Vue.component("PTab", PTab);
Vue.component("PAlert", PAlert);
Vue.component("PTruckFill", PTruckFill);
Vue.component("PFlag", PFlag);
Vue.component("PThumbnailImage", PThumbnailImage);
Vue.component("PButtonGroup", PButtonGroup);
Vue.component("PLinkButton", PLinkButton);

// to be depricated
Vue.component("PProductsPicker", PProductsPicker);

Vue.component("PDisplayComments", PDisplayComments);
Vue.component("PDisplayDate", PDisplayDate);
Vue.component("PDisplayNumber", PDisplayNumber);
Vue.component("PDisplayStatus", PDisplayStatus);
Vue.component("PDisplayText", PDisplayText);
Vue.component("PDisplayProduct", PDisplayProduct);
Vue.component("PDisplayProductImages", PDisplayProductImages);
Vue.component("PDisplaySum", PDisplaySum);
Vue.component("PDisplayUser", PDisplayUser);
Vue.component("PDisplayBoolean", PDisplayBoolean);
Vue.component("PDisplayAutoCommit", PDisplayAutoCommit);
Vue.component("PDisplayAddress", PDisplayAddress);
Vue.component("PDisplayPrice", PDisplayPrice);
Vue.component("PDisplayLocation", PDisplayLocation);
Vue.component("PDisplayInfo", PDisplayInfo);
Vue.component("PDisplayContainerStatus", PDisplayContainerStatus);
Vue.component("PDisplayContainerOrderStatus", PDisplayContainerOrderStatus);
Vue.component("PDisplayContainerRentalStatus", PDisplayContainerRentalStatus);
Vue.component("PDisplayContainerOrderCurrent", PDisplayContainerOrderCurrent);
Vue.component("PDisplayCustomsClearance", PDisplayCustomsClearance);
Vue.component("PDisplaySent", PDisplaySent);
Vue.component("PDisplaySumSent", PDisplaySumSent);
Vue.component("PDisplayScheduleFill", PDisplayScheduleFill);
Vue.component("PDisplayCounterpart", PDisplayCounterpart);
Vue.component("PRentalStatus", PRentalStatus);
Vue.component("PImage", PImage);
Vue.component("PTooltip", PTooltip);
Vue.component("PTranslation", PTranslation);
Vue.component("PAddressItem", PAddressItem);
Vue.component("PVersionChecker", PVersionChecker);

Vue.component("PText", PText);
Vue.component("PTable", PTable);
Vue.component("PButton", PButton);
Vue.component("PInput", PInput);
Vue.component("PInputGln", PInputGLN);
Vue.component("PSelect", PSelect);
Vue.component("PTextarea", PTextarea);
Vue.component("POptionGroup", POptionGroup);
Vue.component("PCheckbox", PCheckbox);
Vue.component("PRadio", PRadio);
Vue.component("PToggle", PToggle);
Vue.component("PNumber", PNumber);
Vue.component("PFile", PFile);
Vue.component("PDatePicker", PDatePicker);
Vue.component("PProductPicker", PProductPicker);
Vue.component("PLocationPicker", PLocationPicker);
Vue.component("PStatusPicker", PStatusPicker);
Vue.component("PWeekdayPicker", PWeekdayPicker);
Vue.component("PPostCodePicker", PPostCodePicker);

Vue.component("POrderFilters", POrderFilters);

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faArrowUp,
	faBalanceScale,
	faBars,
	faBell,
	faBold,
	faCalendar,
	faChartLine,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faComment,
	faConstruction,
	faContainerStorage,
	faDotCircle,
	faEnvelope,
	faExclamation,
	faExclamationCircle,
	faExclamationTriangle,
	faFileExcel,
	faFileInvoice,
	faFilePdf,
	faFilter,
	faForklift,
	faGlobe,
	faHome,
	faImage,
	faInfoCircle,
	faItalic,
	faLevelDown,
	faLifeRing,
	faListOl,
	faListUl,
	faMinusSquare,
	faNewspaper,
	faPallet,
	faPaperPlane,
	faPaste,
	faPhone,
	faPlus,
	faPlusSquare,
	faPrint,
	faQuestionSquare,
	faRecycle,
	faRedo,
	faSearch,
	faSearchMinus,
	faSearchPlus,
	faShoppingCart,
	faSort,
	faSortDown,
	faSortUp,
	faSpinner,
	faSquare,
	faStamp,
	faTable,
	faTachometer,
	faTimes,
	faTrashAlt,
	faTruck,
	faTruckLoading,
	faUndo,
	faUser,
	faUserHardHat,
	faUsersSlash,
	faWarehouseAlt,
} from "@fortawesome/pro-regular-svg-icons";

import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

import { faPersonMilitaryPointing } from "@fortawesome/free-solid-svg-icons";

import { faPalletAlt } from "@fortawesome/pro-duotone-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faArrowUp,
	faBalanceScale,
	faBars,
	faBell,
	faBold,
	faCalendar,
	faChartLine,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faComment,
	faConstruction,
	faContainerStorage,
	faDotCircle,
	faEnvelope,
	faExclamation,
	faExclamationCircle,
	faExclamationCircle,
	faExclamationTriangle,
	faFileExcel,
	faFileInvoice,
	faFilePdf,
	faFilter,
	faForklift,
	faGlobe,
	faHome,
	faImage,
	faInfoCircle,
	faItalic,
	faLevelDown,
	faLifeRing,
	faListOl,
	faListUl,
	faMinusSquare,
	faNewspaper,
	faPallet,
	faPalletAlt,
	faPaperPlane,
	faPaste,
	faPersonMilitaryPointing,
	faPhone,
	faPlus,
	faPlusSquare,
	faPrint,
	faQuestionCircle,
	faQuestionSquare,
	faRecycle,
	faRedo,
	faSearch,
	faSearchMinus,
	faSearchPlus,
	faShoppingCart,
	faSort,
	faSortDown,
	faSortUp,
	faSpinner,
	faSquare,
	faStamp,
	faTable,
	faTachometer,
	faTimes,
	faTrashAlt,
	faTruck,
	faTruckLoading,
	faUndo,
	faUser,
	faUserHardHat,
	faUsersSlash,
	faWarehouseAlt,
);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);

import * as filters from "./filters";
// biome-ignore lint/complexity/noForEach: <explanation>
Object.keys(filters).forEach((key) => {
	Vue.filter(key, filters[key]);
});

import * as directives from "./directives";
// biome-ignore lint/complexity/noForEach: <explanation>
Object.keys(directives).forEach((key) => {
	Vue.directive(key, directives[key]);
});

import ClickOutside from "vue-click-outside";
Vue.directive("click-outside", ClickOutside);

require("@/assets/tailwind.css");

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
