<template>
  <div class="p-4 mt-10 text-center text-red-800 bg-red-200 border-2 border-red-300 rounded-lg">
    <slot> {{ text }}</slot>
  </div>
</template>

<script>
export default {
  name: "p-error",
  props: {
    text: {
      type: String,
      default: null
    }
  }
}
</script>