<template>  
  <span 
    class="inline-block px-4 py-1 font-medium rounded-full" 
    :class="statusClass"
    v-html="displayText"
  >
  </span>
</template>

<script>

export default {

  props: ["item", "value"],

  computed: {

    status () {
      return this.item.status ?? "Open"
    },
    
    statusClass () {
      return this.status === "Open" ? "bg-green-100 text-green-700" :             
                                      "bg-gray-200 text-gray-500"
    },    

    displayText () {
      return this.status === "Open" ? this.$tk("Common.ContainerOrderStatus.Open") : this.status 
    }
  }  
}
</script>