var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ 
    content: _vm.tooltipContent, 
    delay: { 
      show: 100, 
      hide: 100 
    }
  }),expression:"{ \n    content: tooltipContent, \n    delay: { \n      show: 100, \n      hide: 100 \n    }\n  }"}]},[(_vm.customsHandler === 'kgh')?[_vm._v("KGH")]:(_vm.customsHandler !== 'none')?[_c('div',{staticClass:"flex items-center justify-center py-1 text-base text-white rounded-full h-7 w-7",class:{          
        'bg-red-500': _vm.customsStatus === '',
        'bg-orange-500': _vm.customsStatus === 'pending',
        'bg-green-500': _vm.customsStatus === 'done'
      }},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'stamp']}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }