import { getConfig } from "../../config";

const config = getConfig();

function install(Vue) {
  Vue.prototype.$appConfig = {
    assetsBaseUrl: config?.assetsUrl,
  };
}

export default install;
