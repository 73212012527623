<template>
  <div
    class="flex-shrink-0 px-4 py-2 text-xs text-gray-200 flex-0"
    :class="bgColor"
  >
    <div class="items-center justify-between hidden md:flex">
      <div class="flex items-end">
        <router-link :to="{ name: 'home' }">
          <div class="mt-1 md:mt-0">
            <div>
              <img
                :src="logoUrl"
                style="height: 21px"
              />
            </div>
          </div>
        </router-link>

        <PVersionChecker v-slot="{ version }">
          <div class="hidden ml-1 leading-none text-gray-500 md:block">
            APP: {{ version }} | {{ info }}
          </div>
        </PVersionChecker>
      </div>

      <div
        class="text-base font-bold text-teal-400"
        v-if="env"
      >
        {{ env.toUpperCase() }}
        <span
          v-if="env === 'local' && apiEnv !== 'test'"
          class="text-red-600"
        >
          - {{ apiEnv === "" ? "PRODUCTION" : apiEnv }} DATA!!</span>
      </div>

      <div
        class="flex items-center mt-2 space-x-4 md:mt-0"
        v-if="user"
      >
        <button
          class="focus:outline-none focus:text-white hover:text-white hover:underline focus:underline"
          @click="userSelectDialogVisible = true"
        >
          {{ customer.name }}, {{ location.name }}, {{ user.name }}
        </button>

        <div
          class="flex space-x-2"
          v-if="interCompanies.length > 0"
        >
          <a
            class="focus:outline-none hover:underline focus:underline"
            :href="interCompany.url"
            v-for="(interCompany, index) in interCompanies"
            :key="index"
          >
            {{ interCompany.companyCountry }}
          </a>
        </div>

        <PLanguagePicker />

        <button
          class="focus:outline-none focus:text-white hover:text-white hover:underline focus:underline"
          @click.prevent="onLogout"
          v-html="$tk('PUserContext.Logout')"
        ></button>
      </div>
    </div>

    <div class="flex items-center justify-between md:hidden">
      <router-link :to="{ name: 'home' }">
        <img
          :src="logoUrl"
          style="height: 21px"
        />
      </router-link>

      <div class="flex items-center space-x-4">
        <PLanguagePicker />
        <MenuDropdown
          :invert="true"
          :mobileLinks="mobileLinks"
          @userClick="userSelectDialogVisible = true"
        />
      </div>
    </div>

    <PUserSelectDialog
      v-if="userSelectDialogVisible"
      @close="userSelectDialogVisible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { get } from "lodash";
import { reportLevel } from "../enums";
import PUserSelectDialog from "@/dialogs/PUserSelectDialog";
import PLanguagePicker from "./PLanguagePicker";
import MenuDropdown from "@/components/MenuDropdown";
import { getConfig, getTheme } from "@/config";

const theme = getTheme();
const apiUrl = getConfig()?.apiUrl || '';

export default {
  name: "UserContext",
  components: {
    PUserSelectDialog,
    PLanguagePicker,
    MenuDropdown,
  },
  props: ["mobileLinks"],
  data() {
    return {
      userSelectDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters([
      "status",
      "customer",
      "location",
      "user",
      "interCompanies",
      "packageVersion",
    ]),
    canChangeContext() {
      return (
        this.user.isGlobalAdmin ||
        this.user.isCustomerAdmin ||
        this.user.reportLevel >= reportLevel.LOCATION
      );
    },
    info() {
      return get(this.status, "info", "");
    },
    logoUrl() {
      return theme.logoWhite;
    },
    env() {
      const hostname = window.location.hostname;
      console.debug("hostname:", hostname);
      if (hostname.includes("localhost")) {
        return "local";
      } else if (hostname.includes("-test")) {
        return "test";
      } else if (hostname.includes("-qa")) {
        return "qa";
      } else return "";
    },
    apiEnv() {
      let env = "";
      if (apiUrl.includes("-dev") || apiUrl.includes("-test")) env = "test";
      else if (apiUrl.includes("-qa")) env = "qa";
      else if (apiUrl.includes("api365-")) env = "365";

      if (apiUrl.includes("mgw")) env += "Wash DK";
      else if (apiUrl.includes("dan")) env += "DK";
      else if (apiUrl.includes("sve")) env += "SV";
      else if (apiUrl.includes("ppa")) env += "ProviPAl";
      return env;
    },
    bgColor() {
      if (this.env === "local") return "bg-blue-800";
      else if (this.env === "test") return "bg-yellow-400";
      else if (this.env === "qa") return "bg-red-500";
      else return "bg-black";
    },
  },
  methods: {
    onLogout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
