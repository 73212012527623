<template>
  <div class="p-4 text-sm border rounded-lg" :class="alertClasses">
    <slot />
  </div>
</template>

<script>
export default {
  props: {    
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    alertClasses () {
      return {
        "bg-red-200 border-red-300": this.type === "error",
        "bg-orange-200 border-orange-300": this.type === "info",
        "bg-green-100 border-green-200": this.type === "success"
      }
    }
  }
}
</script>

<style scoped>

a {
  text-decoration: underline;
}

</style>