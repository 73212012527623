<template>
  <div 
    class="py-2 text-sm font-semibold border-b-2 cursor-pointer select-none" 
    :class="{ 'border-green-500 text-black': value === name, 'border-transparent text-gray-500': value !== name }" 
    @click="click"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: ["value", "name"],
  methods: {
    click () {
      this.$emit("input", this.name)
    }
  }
}
</script>