<template>
  <div class="flex flex-wrap items-center py-3">
    <div
      v-for="(transaction, index) in item[nested]"
      :key="index"
      :class="{ 'ml-1': index > 0 }"
      _class="bg-white rounded-full shadow h-8 w-8 flex items-center justify-center"
    >
      <img
        alt=""
        :src="`${assetsBaseUrl}/media.ashx?${transaction.productImage}&template=product-icon`"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    nested: {
      type: String,
      default: "transactions"
    }
  },
  computed: {
    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl
    }
  }
}
</script>