<template>
  <div>
    <PLabel
      :label="label"
      :disabled="disabled"
      :required="required"
      :error="!!error"
      @click="$refs.textarea.$el.focus()"
    />
    <TextareaAutosize
      ref="textarea"
      :rows="rows"
      :value="value"
      :autosize="autosize"
      :disabled="disabled"
      :class="classNames"
      :max-height="maxHeight"
      @input="$emit('input', $event)"
      class="block w-full h-20 p-2 border rounded shadow-inner focus:outline-none"
    />
    <PError :error="error" />
  </div>
</template>
<script>
import PLabel from "./partials/PLabel"
import PError from "./partials/PError"
export default {
  name: "p-textarea",
  components: {
    PLabel,
    PError
  },
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "" },
    maxLength: { type: Number, required: false },
    resizable: { type: Boolean, default: false },
    rows: { type: Number, default: 1 },
    disabled: { type: Boolean, default: false },
    error: { type: String, default: "" },
    maxHeight: { type: Number, default: 100 },
    required: { type: Boolean, default: false },
    autosize: { type: Boolean, default: true }    
  },
  computed: {
    classNames () {
      return {        
        "border-gray-400 focus:border-white focus:shadow-outline": !this.error && !this.disabled,
        "bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed": this.disabled,
        "border-red-500 focus:border-red-600 placeholder-red-300 text-red-600": this.error,
        "resize-none": !this.resizable
      }
    }
  },
}
</script>