<template>
  <div class="py-3">{{ item[value] !== null ? numberFormat(item[value]) : "" }}</div>
</template>

<script>
export default {

  name: "p-display-price",

  props: ["item", "value"],

  methods: {
    numberFormat (number) {
      return new Intl.NumberFormat(this.$i18n.locale.replace('-sw', ''), {
        minimumFractionDigits: 2
      }).format(number)
    }
  }
}
</script>
