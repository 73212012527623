<template>
  <div class="flex items-center" :class="{ 'flex-col-reverse': stacked }">
    <button 
      @click.prevent="$emit('input', !value)" 
      role="checkbox" tabindex="0" 
      :aria-checked="value" 
      :disabled="disabled"
      :class="outerClasses"
      class="relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:border-white focus:shadow-outline focus:outline-none" 
    >
      <span 
        aria-hidden="true" 
        class="inline-block bg-white rounded-full shadow translate-x-0 transform transition ease-in-out duration-200" 
        :class="innerClasses"
      ></span>

    </button>
    <div 
      v-if="label" 
      :class="{ 'text-xs text-center mb-1 text-gray-700 leading-none': stacked, 'ml-2 text-sm': !stacked }"
      v-html="label"
    ></div>
  </div>
</template>

<script>
export default {
  name: "p-toggle",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    stacked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    outerClasses () {
      return {
        "cursor-not-allowed": this.disabled,
        "h-5 w-10": !this.stacked,
        "h-4 w-8": this.stacked,
        "bg-green-500": this.value,
        "bg-gray-400": !this.value
      }
    },
    innerClasses () {
      return {
        "h-4 w-4": !this.stacked,
        "h-3 w-3": this.stacked,
        "translate-x-5": this.value && !this.stacked,
        "translate-x-4": this.value && this.stacked,        
        "translate-x-0": !this.value
      }
    }
  }
}
</script>