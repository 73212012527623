<template>
  <div class="flex items-center space-x-2">
    <slot />
  </div>
</template>

<script>
  export default {
    name: "PButtonGroup"
  }
</script>