<template>
  <div class="text-sm" :class="{ 'flex flex-col space-y-2': !horizontal }">
    <button 
      v-for="(item, index) in items" 
      :key="index" 
      
      @click="onChange(index)" 
      class="inline-flex items-center mr-4 select-none group focus:outline-none" 
    >    
      <font-awesome-icon 
        size="lg" 
        :class="{ 'text-green-500': index === selectedIndex }"
        :icon="['far', index === selectedIndex ? 'check-circle' : 'circle']" 
        class="text-base group-hover:text-green-500 group-focus:text-green-500" 
      /> 
      <div class="ml-2">
        {{ item[itemText] }}
      </div>
    </button>
  </div>
</template>

<script>
import { findIndex } from "lodash"
export default {
  name: "p-option-group",
  props: {
    value:  {
      required: true
    },
    items: {
      type: Array
    },
    itemText: {
      type: String,
      default: "text"
    },
    itemValue: {
      type: String,
      default: "value"
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedIndex: -1
    }
  },
  methods: {
    onChange (index) {
      this.$emit("input", this.items[index][this.itemValue])
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.selectedIndex = findIndex(this.items, item => item[this.itemValue] === val)    
      }, immediate: true
    }
  }
}
</script>