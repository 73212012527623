<template>
  <PClickOutside :do="() => isOpen = false">
    <div class="relative -mb-1">
      <template v-if="isSubmitting">
        <FontAwesomeIcon
          :icon="['far', 'spinner']"
          spin
        />
      </template>
      <button
        v-else
        :title="$tk('PLanguagePicker.SelectLanguage', true)"
        @click="isOpen = !isOpen"
      >
        <PFlag
          :country="locale.replace('-sw', '')"
          class="w-6 h-4"
        ></PFlag>
      </button>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="opacity-0 transform scale-95"
        enter-to-class="opacity-100 transform scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="opacity-100 transform scale-100"
        leave-to-class="opacity-0 transform scale-95"
      >

        <div
          v-if="isOpen"
          class="absolute right-0 z-30 p-2 bg-black shadow-xl"
        >
          <button
            v-for="(lang, index) in languages"
            :key="index"
            class="flex items-center p-2 group focus:outline-none"
            @click="setLocale(lang.locale)"
          >
            <PFlag
              class="w-6"
              :country="lang.locale.replace('-sw', '')"
            ></PFlag>

            <div
              :class="{ 'text-white': locale === lang.locale, 'text-gray-400': locale !== lang.locale }"
              class="ml-2 group-hover:text-white"
              v-html="lang.name"
            >
            </div>
          </button>
        </div>

      </transition>
    </div>
  </PClickOutside>
</template>

<script>
import LocaleService from "@/services/LocaleService"
import http from "@/http"

export default {

  data() {
    return {
      isOpen: false,
      languages: [
        { locale: "nb-NO", name: "Norsk" },
        { locale: "sv-SE", name: "Svenska" },
        { locale: "da-DK", name: "Dansk" },
        { locale: "fi-FI", name: "Soumi" },
        { locale: "en-US", name: "English" },
      ],
      isSubmitting: false
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },

  methods: {

    async setLocale(locale) {
      this.isSubmitting = true

      try {
        this.isOpen = false
        await http.post("Language", {
          language: locale.replace('-sw', '')
        })
        LocaleService.setLocale(locale)
        location.reload()

      } catch (error) {
        this.$notifyError(error.reason)
        this.isSubmitting = false
      }
    }
  },

  created() {
    const isSmartWash = location.hostname === 'localhost' || location.hostname.toLowerCase().indexOf("smartwash") >= 0;
    if (isSmartWash) this.languages.map(lang => lang.locale +='-sw')

    const handleEscape = e => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.isOpen = false
      }
    }
    document.addEventListener("keydown", handleEscape)
    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", handleEscape)
    })
  }
}
</script>