<template>
  <PDialog 
    :title="$tk('ProfileDialog.Profile')" 
    classes="left-0 md:left-auto md:w-2/5"
    @close="$emit('close')"
  >
    <PDialogContent v-if="showEditUser">

      <form v-if="user" class="flex flex-col w-full">

        <PInput 
          class="mt-4"
          v-model="user.name" 
          type="text" 
          :disabled="updating"
          :label="$tk('ProfileDialog.Name')" 
        />

        <PInput
          class="mt-4"
          v-model="user.phone"
          type="text"
          :disabled="updating"
          :required="true"
          :label="$tk('ProfileDialog.Phone')"
        />

        <PInput
          class="mt-4"
          v-model="user.email"
          type="email"
          :disabled="updating"
          :required="true"
          :label="$tk('ProfileDialog.Email')"
        />

        <PSelect
          class="w-full mt-4"
          v-model="user.language"
          item-text="label"
          item-value="value"
          :disabled="updating"
          :label="$tk('ProfileDialog.Language')"
          :items="languages"
        />

      </form>
    </PDialogContent>
    <PDialogContent v-else>
      <form class="flex flex-col w-full">

        <PInput
          class="mt-4"
          v-model="oldPassword"
          type="password"
          :disabled="updating"
          :required="true"
          :label="$tk('ProfileDialog.OldPassword')"
        />

        <PInput
          class="mt-4"
          v-model="newPassword"
          type="password"
          :disabled="updating"
          :required="true"
          :label="$tk('ProfileDialog.NewPassword')"
        />

        <PInput
          class="mt-4"
          v-model="confirmPassword"
          type="password"
          :disabled="updating"
          :required="true"
          :label="$tk('ProfileDialog.ConfirmNewPassword')"
        />

        <div class="flex self-end h-full text-right">
         
        </div>

      </form>
    </PDialogContent>

    <PDialogActions class="justify-between">
      
      <a 
        href="#"
        @click.prevent="toggle" 
        class="flex items-center h-10 mt-4 ml-2 underline justify-self" 
        v-html="toggleText"
      ></a>

      <PButton
        v-if="showEditUser"
        :loading="updating"
        @click="updateUser"
        class="self-end mt-4 ml-4"
        >{{ updating ? $tk("Common.Actions.Saving", true) : $tk("Common.Actions.Save", true) }}
      </PButton>

      <PButton
        v-else
        :loading="updating"
        @click="updatePassword"
        class="self-end mt-4 ml-4"
        >{{ updating ? $tk("Common.Actions.Saving", true) : $tk("Common.Actions.Save", true) }}
      </PButton>

    </PDialogActions>
  </PDialog>
 
</template>

<script>

  import api from "../api"

  export default {
    name: "ProfileDialog",
    props: {
      userId: {
        type: String,
        default: undefined
      }
    },
    computed: {
      toggleText () {
        return this.showEditUser ? 
          this.$tk("ProfileDialog.ChangePassword") :
          this.$tk("ProfileDialog.EditProfile")
      }
    },
    data() {
      return {
        showEditUser: true,
        user: undefined,
        updating: false,
        notificationText: undefined,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        languages: [
          {
            value: "da-DK",
            label: this.$t("ProfileDialog.Danish"),
          },
          {
            value: "fi-FI",
            label: this.$t("ProfileDialog.Finnish"),
          },
          {
            value: "nb-NO",
            label: this.$t("ProfileDialog.Norwegian"),
          },
          {
            value: "sv-SE",
            label: this.$t("ProfileDialog.Swedish"),
          },
        ]
      }
    },
    methods: {

      toggle () {
        this.showEditUser = !this.showEditUser
      },

      async updateUser () {
        
        this.updating = true;

        try {
          await api.userPost(
            this.userId,
            this.user.name,
            this.user.phone,
            this.user.email,
            this.user.language
          )
          this.$emit('close')
        } catch (e) {
          
          this.$notifyError(
            this.$tk("ProfileDialog.ErrorSavingProfile"),
            this.$formatApiError(e)
          )

        }

        this.updating = false

      },

      async updatePassword () {

        this.updating = true

        if (this.newPassword !== this.confirmPassword) {

          this.$notifyError(this.$tk("ProfileDialog.PasswordsMustMatch", true))
          this.updating = false

        } else {

          try {

            await api.userPasswordPost(
              this.userId,
              this.oldPassword,
              this.newPassword
            )

            this.$notifySuccess("Passord oppdatert")

          } catch (e) {

            this.$notifyError("Passord ble ikke oppdatert", this.$formatApiError(e))
          
          }

          this.updating = false
        }
      }
    },

    async created () {

      this.user = await api.userGet({ id: this.userId });
      
    }
  }
</script>
