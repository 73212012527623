<template>
  <div>
    <PLabel
      label="GLN" 
      :error="error" 
      :disabled="disabled"
      @click="$refs.input.focus()" 
    />

    <div class="relative flex items-center">

      <input 
        v-model="val"
        @keypress="isNumber"
        placeholder="13 siffer"
        :maxlength="glnLength"
        :disabled="disabled"
        :class="classNames + (error.length > 0 ? 'bg-red-300' : '') + (disabled ? 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed' : '')"
        class="w-full h-10 p-2 leading-none border rounded shadow-inner focus:outline-none"        
      />
      
      <button 
        v-if="val && !disabled"         
        class="absolute right-0 p-2 mr-1 text-gray-600 focus:outline-none focus:text-gray-900 hover:text-gray-900" @click="val = ''">
        <FontAwesomeIcon           
          :icon="['far', 'times']" 
        />
      </button>
    </div>

    <PError :error="error" />
    <PInfo v-if="val.length < glnLength" :text="$t('PInputGLN.PleaseEnterDigits', { glnLength })" />
    <PValid v-else-if="valid" :text="$t('PInputGLN.ValidGLN')" />
  </div>
</template>

<script>
import PLabel from "./partials/PLabel"
import PError from "./partials/PError"
import PValid from "./partials/PValid"
import PInfo from "./partials/PInfo"
import http from "@/http.js"

export default {
  name: "PInputGLN",
  components: {
    PLabel,
    PError,
    PInfo,
    PValid
  },
  data() {
    return {
      val: this.value,
      valid: false,
      error: '',
      classNames: '',
      glnLength: 13
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validate: async function(value) {
      if (value.length === 13) {
        let response =  await http.get("GLNValidate", { params : { gln: value }})
        if (response.ok === false) {
          this.error = this.$tk("PInputGLN.InvalidGLN", true) + ": " + response.reason
        } else {
          this.valid = true
          this.$emit("valid", this.valid)
        }
      }
      if (value.length === 0) {
        this.valid = true
        this.$emit('valid', this.valid)
      }
    }
  },
  watch: {
    async val(value) {
      this.error = "", this.valid = false
      this.$emit("valid", this.valid)
      this.$emit("input", value)
      this.validate(value)
    },
  },
  created () {
    this.validate(this.val);
  },
}
</script>