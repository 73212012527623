<template>
  <div class="flex items-end space-x-4">
    <slot :version="currentPackageVersion">
      {{ currentPackageVersion }}
    </slot>    
    <button @click.prevent="onUpdate" class="px-2 text-white bg-green-600 rounded hover:bg-green-500" v-if="updateAvailable">
      <PTranslation k="PVersionChecker.UpdateIsAvailable" />
    </button>
  </div>
</template>

<script>

import { 
  setIntervalAsync, 
  clearIntervalAsync  
} from "set-interval-async/dynamic"
import axios from "axios"

export default {
  
  data () {
    return {      
      timer: null,
      latestPackageVersion: "",
      updateAvailable: false
    }
  },  

  computed: {
    
    currentPackageVersion () {
      return process.env.PACKAGE_VERSION
    }

  },

  methods: {

    async checkVersion () {
      
      if (this.updateAvailable) return
      
      try {
        const { data } = await axios.get(`/version.json?timestamp=${new Date().getTime()}`)        
        this.latestPackageVersion = data
        if (data != this.currentPackageVersion) {
          this.updateAvailable = true
        }
      } catch (error) {
        this.$appInsights.trackException({ exception: error })
      }     
    },

    onUpdate () {
      location.reload()
    }

  },
  
  async created () {
    await this.checkVersion()
    this.timer = setIntervalAsync(async () => {
      await this.checkVersion()
    }, 30000)    
  },

  async beforeDestroy () {
    if (this.timer) {
      await clearIntervalAsync(this.timer)
    }
  }


}
</script>