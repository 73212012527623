var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"w-full border-collapse",attrs:{"cellspacing":"0","cellpadding":"0","border":"0"}},[_c('thead',[_c('tr',[(_vm.selectable)?_c('th',{staticClass:"bg-gradient-to-b from-gray-500 to-gray-600",attrs:{"width":"30"}}):_vm._e(),_vm._l((_vm.allCols),function(col,index){return _c('th',{key:index,staticClass:"font-medium text-white select-none leading-4",class:{ 
          'bg-gradient-to-b hover:from-gray-400 hover:to-gray-500 cursor-pointer': col.sortable, 
          'bg-gradient-to-b from-gray-400 to-gray-500': col.value === _vm.orderByField, 
          'bg-gradient-to-b from-gray-500 to-gray-600': col.value !== _vm.orderByField 
          },attrs:{"width":col.width,"align":col.align},on:{"click":function($event){return _vm.$emit('sort', col)}}},[_c('div',{staticClass:"p-4",class:{ 'flex justify-between': col.sortable }},[_c('div',{domProps:{"innerHTML":_vm._s(col.text)}}),(col.sortable)?_c('FontAwesomeIcon',{staticClass:"ml-2",attrs:{"icon":[
              'far',
              _vm.orderByField === col.value
                ? _vm.orderByDescending
                  ? 'sort-down'
                  : 'sort-up'
                : 'sort'
            ]}}):_vm._e()],1)])}),(_vm.expandable)?_c('th',{staticClass:"bg-gradient-to-b from-gray-500 to-gray-600",attrs:{"width":"30"}}):_vm._e()],2)]),_vm._l((_vm.orders),function(order,index){return _c('POrderGridRow',{key:index,attrs:{"nested":_vm.nested,"cols":_vm.cols,"order":order,"index":index,"expandable":_vm.expandable,"expanded":_vm.expandAll,"selectable":_vm.selectable,"selected":_vm.isSelected(order)},on:{"select":function($event){return _vm.$emit('select', order)},"click":function($event){return _vm.$emit('click', order, $event)}}})}),(_vm.showFooter)?_c('tfoot',[_c('tr',[_c('td',{staticClass:"sticky bottom-0",attrs:{"colspan":_vm.allCols.length + 1}},[_c('div',{staticClass:"px-4 py-2 bg-white border-t border-gray-300 'text-gray-800"},[(_vm.loading)?_c('div',{staticClass:"flex items-center"},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'spinner'],"pulse":""}}),_c('div',{staticClass:"ml-2"},[_c('PTranslation',{attrs:{"k":"Common.General.PleaseWait"}})],1)],1):[_vm._v(" "+_vm._s(_vm.$t("POrderGridTable.ShowingXofYOrders", { count: _vm.orders.length, total: _vm.totalCount }))+" ")]],2)])])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }