<template>
  <div>

    <div class="mb-1 text-sm" v-if="label" v-html="label"></div>

    <label class="flex items-center" v-if="mode === 'select'">
      
      <div 
        class="flex-1 h-10 max-w-4xl p-2 text-sm whitespace-no-wrap border select-none file-input rounded-l-md focus:outline-none" 
        :class="inputClasses"
        v-html="text"
      ></div>

      <button 
        class="flex-grow-0 h-10 p-2 text-sm whitespace-no-wrap border-t border-b border-r rounded-r select-none focus:outline-none"         
        :class="buttonClasses"
        @click.prevent="onButtonClick"
        v-html="multiple ? $tk('PFile.SelectFiles') : $tk('PFile.SelectFile')"
      ></button>

      <button
        class="flex items-center justify-center flex-grow-0 w-10 h-10 ml-1 border rounded focus:outline-none" 
        ref="pasteButton"
        :disabled="disabled"
        :class="buttonClasses"
        @click.prevent="onSetPasteMode"
        v-if="allowPaste"
        v-tooltip="{ content: $t('PFile.PasteImageFromClipboard') }" 
      >
        <FontAwesomeIcon :icon="['far', 'paste']" />
      </button>

      <input 
        class="hidden" 
        type="file" 
        ref="file" 
        :multiple="multiple"
        :accept="accept"
        @change="onFileChange" 
        :disabled="disabled"
      />
    </label>

    <div v-else class="flex items-center">
      <input 
        class="w-full h-10 text-sm text-center border border-gray-400 rounded focus:outline-none focus:shadow-outline"
        placeholder="CTRL+V"
        ref="pasteInput"
        readonly 
        @blur="onPasteBlur" 
        @paste="onPaste"        
      />      
    </div>

    <p-error :error="error" />

  </div>
</template>

<script>

import PError from "./partials/PError"
import { retrieveImageFromClipboardAsBlob } from "@/helpers"

const MODE_SELECT = "select"
const MODE_PASTE = "paste"

export default {
  
  name: "p-file",
  
  components: {
    PError
  },

  props: {    
    label: { // TODO: implement this
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false    
    },    
    accept: {
      type: String,
      default: ".pdf,.jpg,.png,.gif"
    },
    allowPaste: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      files: [],
      mode: MODE_SELECT
    }
  },

  computed: {

    text () {
      return this.files.length === 1 ? this.files[0].name :
             this.files.length > 1 ? `${this.files.length} ${this.$tk('PFile.FilesSelected')}` : ""      
    },

    inputClasses () {
      return {
        "bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed": this.disabled,
        "bg-white border-gray-400 focus:border-gray-500 cursor-pointer": !this.error && !this.disabled,
        "border-red-500 focus:border-red-600 placeholder-red-300 text-red-600 cursor-pointer": this.error,
        "pl-9": this.iconInsideLeft
      }
    },

    buttonClasses () {
      return {
        "bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed": this.disabled,
        "bg-gray-50 border-gray-400 focus:border-white cursor-pointer focus:shadow-outline": !this.error && !this.disabled,
        "border-red-500 focus:border-red-600 placeholder-red-300 text-red-600 cursor-pointer": this.error,
      }
    }

  },

  methods: {

    onSetPasteMode () {
      if (!this.disabled) {
        this.mode = MODE_PASTE
        this.$nextTick(() => {
          this.$refs.pasteInput.focus()
        })
      }
    },

    onPasteBlur () {
      this.mode = MODE_SELECT
    },

    onPaste (e) {
      retrieveImageFromClipboardAsBlob(e, (imageBlob) => { 
        this.files = [ imageBlob ]
        this.$emit("change", [ imageBlob ])
        this.mode = MODE_SELECT
        this.$nextTick(() => {
          this.$refs.pasteButton.focus()
        })
      })
    },

    onButtonClick () {
      this.$refs.file.click()
    },

    onFileChange () {
      const files = this.$refs.file.files //[0]
      this.files = files
      this.$emit("change", files)
    }

  }

}
</script>

<style scoped>

.file-input {
  min-width: 150px;
  max-width: 270px; 
  overflow: hidden; 
  text-overflow: ellipsis;
}

</style>